<template>
  <div class="dashboard">
    <DefaultLayout>
      <div class="dashboard__main-section">
        <div class="dashboard__title">
          <h2 class="fs-32">{{ $t("SIDE_MENU.GENERAL_LOOK") }}</h2>
          <p class="fs-18">{{ $t("DASHBOARD.SUBTITLE") }}</p>
        </div>
        <div class="dashboard__actions">
          <!-- <Button>
            <span class="fs-16">{{ $t("DASHBOARD.CREATE_NEW_REQUEST") }}</span>
            <img class="mx-2" src="@/assets/images/icons/add.svg" />
          </Button> -->
          <!-- <Button>
            <span class="fs-16">{{ $t("GLOBAL.EXPORT_REPORTS") }}</span>
            <img class="mx-2" src="@/assets/images/icons/export.svg" />
          </Button> -->
        </div>
      </div>
      <div class="dashboard__numbers">
        <InfoNumbers :numbers="numbers" :data="data" />
      </div>
      <div class="dashboard__charts">
        <b-row>
          <b-col lg="6">
            <div class="system-card">
              <div class="dashboard__table-title">
                <h6 class="fs-18">{{ $t("DASHBOARD.INCOME") }}</h6>
              </div>
              <BarChart
                class="chart-test"
                :chartdata="barOneChartData"
                :key="barOneChartData.datasets.length"
                :options="chartOptions"
              />
            </div>
          </b-col>
          <b-col lg="3">
            <div class="system-card">
              <div class="dashboard__table-title">
                <h6 class="fs-18">{{ $t("DASHBOARD.REGIONS") }}</h6>
              </div>
              <DoughnutChart
                class="chart-test"
                :chartdata="doughnutData"
                :key="doughnutData.datasets.length"
                :options="chartOptions"
              />
            </div>
          </b-col>
          <b-col lg="3">
            <div class="system-card">
              <div class="dashboard__table-title">
                <h6 class="fs-18">{{ $t("DASHBOARD.DAYS") }}</h6>
              </div>
              <BarChart
                class="chart-test"
                :chartdata="barTwoChartData"
                :key="barTwoChartData.datasets.length"
                :options="chartOptions"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="dashboard__table">
        <b-row>
          <b-col lg="8">
            <div class="system-card">
              <div class="dashboard__table-title">
                <h6 class="fs-18">{{ $t("DASHBOARD.AVAILABLE_DRIVERS_TODAY") }}</h6>
              </div>
              <b-table
                class="custom-table"
                :items="drivers"
                :busy="loading"
                :fields="fields"
                responsive
                :sticky-header="true"
                show-empty
                :tbody-transition-props="transProps"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" />
                  </div>
                </template>

                <template #empty>
                  <div class="text-center p-4">
                    {{ $t("GLOBAL.NO_DATA") }}
                  </div>
                </template>
                <template #cell(id)="data">
                  <div>
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div>
                    <div class="table-profile">
                      <img
                        :src="data.item.avatar != null ? data.item.avatar.url : default_avatar"
                      />
                      <p>{{ data.item.name }}</p>
                    </div>
                  </div>
                </template>
                <template #cell(car_size)="data">
                  <div>
                    <div class="table-profile">
                      <p>
                        {{ lang == "ar" ? data.item.car_size.name_ar : data.item.car_size.name_en }}
                      </p>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="system-card">
              <div class="dashboard__table-title">
                <h6 class="fs-18">{{ $t("DASHBOARD.ASSET_COUNT") }}</h6>
                <div class="assets-count-container">
                  <div class="assets-count-one">
                    <p>24~18</p>
                    <p>6,710</p>
                  </div>
                  <div class="assets-count-two">
                    <p>24~18</p>
                    <p>6,710</p>
                  </div>
                  <div class="assets-count-three">
                    <p>24~18</p>
                    <p>6,710</p>
                  </div>
                  <div class="assets-count-four">
                    <p>24~18</p>
                    <p>6,710</p>
                  </div>
                  <div class="assets-count-five">
                    <p>24~18</p>
                    <p>6,710</p>
                  </div>
                  <div class="assets-count-six">
                    <p>24~18</p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import InfoNumbers from "@/components/Modules/Dashboard/InfoNumbers/index.vue"
import BarChart from "@/components/Charts/BarChart"
import DoughnutChart from "@/components/Charts/DoughnutChart"
import { getDashboardData } from "@/api/dashboard.js"

export default {
  components: {
    InfoNumbers,
    BarChart,
    DoughnutChart
  },
  data() {
    return {
      numbers: [],
      transProps: {
        name: "flip-list"
      },
      loading: false,
      default_avatar: require("@/assets/images/user.png"),
      data: [],
      lang: localStorage.getItem("lang"),
      fields: [
        { key: "id", label: "#", sortable: false },
        {
          key: "name",
          label: this.$t("GLOBAL.NAME"),
          sortable: false
        },
        {
          key: "car_size",
          label: this.$t("REQUESTS.CAR_TYPE"),
          sortable: false
        }
      ],
      drivers: [
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        },
        {
          id: 1,
          account: "عمر نائل",
          area: "المنطقة",
          join_date: "3م - 3 يناير 23",
          done_requests: "16 الف",
          now_requests: "3الاف",
          profits: "248",
          profile_img: require("@/assets/images/icons/avatar.svg")
        }
      ],
      doughnutData: {
        labels: ["Red", "Blue", "Yellow"],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 50, 100],
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
            hoverOffset: 4
          }
        ]
      },
      barOneChartData: {
        labels: ["3,240", "1,239", "8,012", "3,123", "2,198", "8,520"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#12B76A",
            data: [0, 1, 2, 3, 4, 5]
          }
        ]
      },
      barTwoChartData: {
        labels: ["3,240", "1,239", "8,012", "3,123", "2,198", "8,520"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#F05D7B",
            data: [0, 1, 2, 3, 4, 5]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.getDashboardData()
  },
  methods: {
    getDashboardData() {
      this.loading = true
      this.ApiService(getDashboardData()).then((res) => {
        console.log(res.data.data.online_users)
        if (res.data.status == "success") {
          this.data = res.data.data
          this.drivers = res.data.data.online_users
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
