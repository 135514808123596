<template>
  <div class="info-numbers">
    <b-row>
      <b-col lg="3">
        <div class="info-numbers__card">
          <div class="info-numbers__title">
            <img src="@/assets/images/icons/complete-requests.svg" />
            <h6 class="fs-18">{{ $t("DASHBOARD.COMPLETE_REQUESTS_NUMBER") }}</h6>
          </div>
          <div class="info-numbers__info">
            <div class="count">
              <h4 class="fs-36">{{ data.requests_completed }}</h4>
              <p class="fs-14">{{ data.requests_last_week }} {{ $t("DASHBOARD.LAST_WEEK") }}</p>
            </div>
            <div class="good-progress-status">
              <span class="fs-14">2.8%</span>
              <img src="@/assets/images/icons/up.svg" />
            </div>
            <div class="warning-progress-status d-none">
              <span class="fs-14">2.8%</span>
              <img src="@/assets/images/icons/down.svg" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="3">
        <div class="info-numbers__card">
          <div class="info-numbers__title">
            <img src="@/assets/images/icons/profits.svg" />
            <h6 class="fs-18">{{ $t("DASHBOARD.PROFITS") }}</h6>
          </div>
          <div class="info-numbers__info">
            <div class="count">
              <h4 class="fs-36">6,560</h4>
              <p class="fs-14">7,000 {{ $t("DASHBOARD.LAST_WEEK") }}</p>
            </div>
            <div class="good-progress-status d-none">
              <span class="fs-14">2.8%</span>
              <img src="@/assets/images/icons/up.svg" />
            </div>
            <div class="warning-progress-status">
              <span class="fs-14">2.8%</span>
              <img src="@/assets/images/icons/down.svg" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="3">
        <div class="info-numbers__card">
          <div class="info-numbers__title">
            <img src="@/assets/images/icons/pieces.svg" />
            <h6 class="fs-18">{{ $t("DASHBOARD.MOVABLE_PIECES") }}</h6>
          </div>
          <div class="info-numbers__info">
            <div class="count">
              <h4 class="fs-36">1,211</h4>
              <p class="fs-14">320 {{ $t("DASHBOARD.LAST_WEEK") }}</p>
            </div>
            <div class="good-progress-status">
              <span class="fs-14">2.8%</span>
              <img src="@/assets/images/icons/up.svg" />
            </div>
            <div class="warning-progress-status d-none">
              <span class="fs-14">2.8%</span>
              <img src="@/assets/images/icons/down.svg" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="3">
        <div class="info-numbers__card info-numbers__card--waiting">
          <div class="info-numbers__title info-numbers__title--waiting-title">
            <img src="@/assets/images/icons/waiting.svg" />
            <h6 class="fs-18">{{ $t("DASHBOARD.WAITING_ORDERS") }}</h6>
          </div>
          <h4 class="info-numbers__count fs-36">{{ data.requests_waiting }}</h4>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    numbers: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>