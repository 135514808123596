<script>
import { Doughnut } from "vue-chartjs";
import "chartjs-plugin-labels";
export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    this.handleRenderChart();
  },
  methods: {
    handleRenderChart() {
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>
